import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px 5%",
    [theme.breakpoints.up("xl")]: {
      margin: "20px 10%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px 10px",
      fontSize: 14,
    },

    "& .coming-logo": {
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
  },
  popUp: {
    zIndex: 99,
    color: "#fff",
    position: "absolute",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
    "& .wrapper": {
      width: "30%",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "85%",
        position: "fixed",
        top: "30%",
      },
    },
    "& .icon-close": {
      width: "10%",
      position: "absolute",
      top: "2%",
      right: "6%",
      zIndex: 100,
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .popup-img": {
      width: "100%",
      maxWidth: "600px",
      [theme.breakpoints.down("sm")]: {
        width: "85%",
        position: "fixed",
        top: "30%",
      },
    },
  },
}));
